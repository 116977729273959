import React from 'react'
import SEO from '../components/Blocks/SEO'
import Terms from '../components/Pages/Terms'

function TermsPage(props) {
  return (
    <React.Fragment>
      <SEO
        title="Villkor och Policyer"
        description="Villkor och policyer som gäller för casinoindex.se"
      />
      <Terms />
    </React.Fragment>
  )
}

export default TermsPage
