import React from 'react'
import styles from './Terms.module.css'
import Page from '../Layout/Page'
import Header from '../Layout/Header'
import Hero from '../Blocks/Hero'
import Content from '../Blocks/Content'

function Terms(props) {
  return (
    <React.Fragment>
      <Header>
        <Hero heading="Villkor och Policyer">
          <p className={styles.preamble}>
            På den här sidan kan du läsa om de villkor och policyer som gäller
            för casinoindex.se
          </p>
        </Hero>
      </Header>
      <Page>
        <section>
          <Content>
            <h2>Kakpolicy (Cookies policy) för CasinoIndex.se</h2>
            <p>
              Här beskrivs det vad en cookie är, hur och varför de ibland
              används på den här webbplatsen och hur du kan neka till
              användningen av kakor.
            </p>
            <h3>Vad är kakor (cookies)?</h3>
            <p>
              Kakor är små textfiler med information som lagras på en dator,
              mobil eller annan enhet som används för att besöka en webbsida.
              Kakor är användbara för att förbättra upplevelsen för besökaren av
              webbsidan genom att till exempel komma ihåg användarinställningar
              över en viss tid. Kakor kan även vara användbara för att göra det
              möjligt att känna igen en enhet och på så sätt ge användaren
              relevant information och marknadsföring. Du kan hitta mer
              information om kakor på{' '}
              <a
                className={styles.link}
                rel="nofollow"
                href="https://pts.se/sv/bransch/regler/lagar/lag-om-elektronisk-kommunikation/kakor-cookies/"
              >
                Post- och Telestyrelsens webbsida
              </a>
            </p>
            <h3>Hur länge lagras kakor (cookies)?</h3>
            <p>
              <strong>Sessionskakor (Session cookies)</strong> är temporära
              kakor som raderas då användaren stänger webbläsaren.
            </p>
            <p>
              <strong>Persistenta kakor (Persistent cookies)</strong> lagras på
              användarens enhet under den bestämda tid som specificerats i kakan
              om inte användaren själv tagit bort kakan innan denna tidpunk.
            </p>
            <h3>Vår användning av kakor (cookies).</h3>
            <p>
              På vår webbplats används kakor och så kallad localStorage för
              webbstatistik och för att lagra inställningar som du gör på
              webbplatsen.
            </p>
            <ul>
              <li>
                <p>
                  Accepterandet av kakor. Om du väljer att acceptera användandet
                  av kakor på vår webbplats kommer vi att komma ihåg det i 30
                  dagar så att du inte behöver acceptera det varje gång sidan
                  laddas.
                </p>
              </li>
              <li>
                <p>
                  Google Analytics. Google Analytics samlar anonym statistik om
                  besökare på webbplatsen.
                </p>
              </li>
            </ul>
            <h3>Neka till användandet av kakor.</h3>
            <p>
              Det är möjligt att via funktioner i webbläsaren neka till, granska
              och radera kakor som har lagrats i din webbläsare. Du kan läsa mer
              om hur du gör detta i dokumentationen för din webbläsare. På
              webbsidan{' '}
              <a
                className={styles.link}
                rel="nofollow"
                href="http://www.minacookies.se/allt-om-cookies/cookiehantering/"
              >
                minacookies.se
              </a>{' '}
              finns länkar direkt till dokumentationen om cookies från några av
              de vanligaste webbläsarna.
            </p>
          </Content>
        </section>
      </Page>
    </React.Fragment>
  )
}

export default Terms
